import { ProductPriceService } from '../../../services/product-price.service';
import { Component, OnInit } from '@angular/core';
import { Order, OrderItem, OrdersService } from '../../../services/orders.service';
import { Customer } from '../../../services/customers.service';
import { SuppliersService, Supplier } from '../../../services/suppliers.service';
import { Product, ProductsService } from '../../../services/products.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TransportOrder, TransportOrderItem } from '../../../services/transport-order.service';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';

@Component({
  selector: 'app-order-edit',
  templateUrl: './order-edit.component.html',
  styleUrls: ['./order-edit.component.css']
})
export class OrderEditComponent implements OnInit {
  _order: Order;
  _newOrder = true;
  loading = false;

  transportCompany: String;
  cancelReason: string;
  singleCustomerID: number;

  test: Date;

  currentModal: NgbModalRef;

  constructor(private route: ActivatedRoute, private router: Router, 
    private modalService: NgbModal, private supplierService: SuppliersService,
    private orderService: OrdersService, private priceService: ProductPriceService, private productService: ProductsService) { }

  ngOnInit() {
    this.loading = true;
    this.test = new Date();
    this.route.params.subscribe(params => {
      const id = params['id'];
      if (id === '0') {
        this._order = new Order();
        this._order.customerOrders.push(new OrderItem()); // always at least one customer
        this.orderLoaded();
      } else {
        this._newOrder = false;
        this.orderService.getOrder(id).subscribe(o => {
          this._order = o;
          this.orderLoaded();
        });
      }
    });

  }

  // need to do this after loading the order in case this is an old order and the supplier or product has been deactivated
  orderLoaded() {
    this.loading = false;
  }

  calcTotal() {
    return this._order.customerOrders.map(o => o.quantity).reduce((a, b) => a + b, 0);
  }

  save() {
    this.loading = true;
    console.log(this._order.pickupDate, moment(this._order.pickupDate).utcOffset(10));
    this._order.pickupDate = moment(this._order.pickupDate).utcOffset(10).toDate();    // fix date only values so they are correct for timezone
    this._order.deliveryDate = moment(this._order.deliveryDate).utcOffset(10).toDate();
    // this._order.deliveryDate.setHours(12);  // fix date only values so they are correct for timezone
    if (this._newOrder) {
      this.orderService.createOrder(this._order).subscribe(res => {
        this.router.navigate(['/orders/' + res.orderID.toString()]);
      }, err => {
        console.log(err);
        alert(err.error);
        this.loading = false;
      });
    } else {
      this.orderService.saveOrder(this._order).subscribe(res => {
        this.loading = false;
      }, err => {
        console.log(err);
        alert(err.error);
        this.loading = false;
      });
    }
  }



//   toUTCDate(date: Date): Date {
//     if (date) {
//         const tmp = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
//         return tmp;
//     } else {
//         return null;
//     }
// }

  cancel() {
    this.router.navigate(['/orders']);
  }

  addCustomer() {
    this._order.customerOrders.push(new OrderItem());
  }
  removeCustomer(index: number) {
    if (!window.confirm('Are you sure you wish to remove this customers\' order?')) { return; }
    this._order.customerOrders.splice(index, 1);
  }

  handleFileInput(files: FileList, orderItemID: number) { // called each time file input changes
    if (files.item(0) === undefined) {
      return;
    }

    // default to the person's container unless the container property has been explicitly set
    this.orderService.uploadCustomerOrderDocument(this._order, orderItemID, files.item(0)).subscribe(res => {
      const custOrder = this._order.customerOrders.find(o => o.orderCustomerItemID === orderItemID);
      custOrder.documentDownloadURL = res;
    }, err => {
      console.log(err);
    });
  }

  onCustomerSelected(cust: Customer) {
    if(this._order.customerOrders.length == 1) {
      this._order.supplierID = null;
      this._order.productID = null;
      this.singleCustomerID = cust.customerID;
    } else {
      this.singleCustomerID = null;
    }
  }

  /**
   * Once a supplier is selected, the list of available products is displayed
   *
   */
  onSupplierSelected(supplier: Supplier) {
    this._order.productID = null; // reset product when changing supplier in case they don't supply it
  }

  onProductSelected(product: Product) {
    this._order.productID = product.productID;
    this.initialisePrices();
  }

  private initialisePrices() {
    const prices = this.priceService.getPricingDetails(this._order.productID, this.singleCustomerID, this._order.supplierID);
    if(prices != null) {
      this._order.customerOrders.forEach(order => {
        order.sellPrice = prices.sellPrice || order.sellPrice;
      });
      this._order.buyPrice = prices.buyPrice || this._order.buyPrice;
      this._order.excise = prices.excise || this._order.excise;
    }
  }

  totalCost() {
    return Number((this._order.buyPrice * this.calcTotal())) + Number((this._order.transportCosts || 0) * this.calcTotal());
  }
  totalIncome() {
    return Number(this._order.customerOrders.map(o => (o.quantity * o.sellPrice)).reduce((a, b) => a + b, 0));
  }
  profitMargin() {
    if (!this.totalIncome) { return -1; }
    return ((this.totalIncome() - this.totalCost()) / this.totalIncome());
  }
  invalidProfitMargin() {
    return (this.profitMargin() < -0.5 || this.profitMargin() > 0.5);
  }

  showCancelPopup(modalContent) {
    this.currentModal = this.modalService.open(modalContent, { centered: true });
  }
  noCancel() {
    this.currentModal.close();
  }
  yesCancel() {
    this.orderService.cancelOrder(this._order, this.cancelReason).subscribe(res => {
      this._order = res;
      this.currentModal.close();
    }, err => {

    });
  }

  downloadOrder() {
    this.loading = true;
    this.orderService.saveOrder(this._order).subscribe(res => {
      this.orderService.generateOrder(this._order).subscribe(url => {
        this.loading = false;
        window.open(url, '_blank');
      }, err => {
        alert('An error occurred attepting to generate order document');
        this.loading = false;
      });
    }, err => {
      this.loading = false;
      alert('An error occurred saving order');
    });
  }


  sendToSupplier() {
    if(!this.supplierService.confirmSendEmail(this._order.supplierID)) return;

    this.loading = true;
    this.orderService.saveOrder(this._order).subscribe(res => {
      this.orderService.sendOrder(this._order).subscribe(order => {
        this.loading = false;
        this._order = order;
      }, err => {
        alert('An error occurred sending email to supplier');
        this.loading = false;
      });
    }, err => {
      this.loading = false;
      alert('An error occurred saving transport operator');
    });
  }

  //#region Transport Order Functionality

  showTransportOrder(modalContent) {
    this.currentModal = this.modalService.open(modalContent, { centered: true, size: 'lg' });
  }
  createTransportOrder(modalContent) {
    if (!this._order.transportOrder) {
      this._order.transportOrder = new TransportOrder();
      this._order.transportOrder.items.push(this.getMeAsTransportItem());
    }
    this.currentModal = this.modalService.open(modalContent, { centered: true, size: 'lg' });
  }
  private getMeAsTransportItem() {
    const meAsItem = new TransportOrderItem();
    meAsItem.supplierOrderID = this._order.orderID;
    meAsItem.supplierID = this._order.supplierID;
    const supp = this.supplierService.getSupplier(this._order.supplierID);
    meAsItem.supplierCode = supp.code || supp.companyName;
    meAsItem.productID = this._order.productID;
    const prod = this.productService.getProduct(this._order.productID);
    meAsItem.productCode = prod.code || prod.description;
    meAsItem.supplierOrderID = this._order.orderID;
    meAsItem.quantity = this._order.totalQuantity;
    return meAsItem;
  }
  onSaveTransportOrder(tpOrder: TransportOrder) {
    this._order.transportOrder = tpOrder;
  }
  onCancelEdit() {
    if(!this._order.transportOrder.transportOrderID) {
      this._order.transportOrder = null;
    }
    this.currentModal.close();
  }

  //#endregion
}


